import React from 'react';
import NavbarTwo from './component/Navbar/NavbarTwo';
import FBanner from './component/Banner/frelencerBanner';
import ServiceTwo from './component/ServiceTwo';
import TestimonialTwo from './component/Testimonial/TestimonialTwo';
import Clientslogo from './component/Clientslogo';
import ContactTwo from './component/ContactTwo';
import SkillTwo from './component/Skill/SkillTwo';
import FooterTwo from './component/FooterTwo';
import jhonData from './component/jhonData';
import DAbout from "./component/Developer/DeveloperAbout";
import DAwards from "./component/Developer/DeveloperAwards";
import DeveloperExperience from "./component/Developer/DeveloperExperience";
import DeveloperPortfolio from "./component/Developer/DeveloperPortfolio";

export const Homefrelencer = () => (
  <div className="body_wrapper">
    <NavbarTwo mClass="menu_two" mainlogo="logo-hype-monkey-small.png" stickylogo="logo-hype-monkey-small.png" />
    <FBanner jhonData={jhonData} />
    <ServiceTwo rowFlex="flex-row-reverse" />
    <DAbout jhonData={jhonData} />
    {/* <SkillTwo /> */}
    {/* <TestimonialTwo /> */}
    <DeveloperExperience />
    <DeveloperPortfolio />
    {/* <DAwards jhonData={jhonData} /> */}
    <Clientslogo />
    <ContactTwo jhonData={jhonData} />
    <FooterTwo jhonData={jhonData} />
  </div>
)
