import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import CounterItem from '../Skill/CounterItem';
class SkillTwo extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: 'easeOutCubic',
        update() {
          el.innerText = data.count.toLocaleString();
        }
      });

    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll('[data-countup]');

    els.forEach(makeCountup);
  }
  render() {
    return (
      <section className="skill_area_two bg_color" id="skill">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="skill_content_two">
                <SectionTitleTwo stitle="Notre groupe" btitle="En quelques chiffres" />
                <p>Dans un environnement en constante évolution, projetez-vous dans une vision d’avenir pour rendre votre activité plus agile !</p>
                <div className="row">
                  <CounterItem col="col-md-6 s_item" CText='30' pdescription="Ans d'expérience" />
                  <CounterItem col="col-md-6 s_item" CText='120' pdescription='Clients' />
                  <CounterItem col="col-md-6 s_item" CText='4' pdescription='Récompenses' />
                  <CounterItem col="col-md-6 s_item" CText='1' pdescription='Tesla' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="fact_img" src={require('../../image/mobile.svg')} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default SkillTwo;