import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import SectionTitleTwo from "../component/Banner/SectionTitleTwo";
import Form from "./Form";
import Map from "./Map";
class ContactTwo extends Component {
  render() {
    let { secClass } = this.props;
    return (
      <section
        className={`contact-area contact-area-two bg_color ${secClass}`}
        id="contacts"
      >
        <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="get_info">
                <SectionTitleTwo
                  stitle="Coordonnées"
                  btitle="Me parler ou me rencontrer"
                />
                </div>
                </div>
                </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="get_info">
                <div className="media get_item">
                  <i className="flaticon-phone"></i>
                  <div className="media-body">
                    <h6>Téléphone</h6>
                    <a href=".#">+33 603 167 399</a>
                  </div>
                </div>
                <div className="media get_item">
                  <i className="flaticon-chat"></i>
                  <div className="media-body">
                    <h6>Email</h6>
                    <a href=".#">contact@hype-monkey.com</a>
                  </div>
                </div>
                
                
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <Reveal effect="fadeInRight" duration={800}>
              <div className="media get_item">
                  <i className="flaticon-pin"></i>
                  <div className="media-body">
                    <h6>Siège social</h6>
                    <p>Résidence Le CASTEL</p>
                    <p>19 Rue MAZENOD</p>
                    <p>13002 Marseille</p>
                  </div>
                </div>
                {/* <Map /> */}
                {/* <div className="media get_item">
                  <i className="flaticon-sound"></i>
                  <div className="media-body">
                    <h6>Support</h6>
                    <a href=".#">Chat</a>
                    <iframe class="_49_rs" title="ChatBot for Lead Generation" aria-label="ChatBot for Lead Generation" scrolling="no" src="https://www.virtualspirits.com/vsa-wix/wixwidget.aspx?pageId=masterPage&amp;compId=comp-k9acxjtl&amp;viewerCompId=comp-k9acxjtl&amp;siteRevision=69&amp;viewMode=site&amp;deviceType=desktop&amp;locale=fr&amp;tz=Europe%2FParis&amp;regionalLanguage=fr&amp;width=280&amp;height=93&amp;instance=KCDyGApZVZrVGXy40mioYIHcry4hE0tZ1i6whsqr4zw.eyJpbnN0YW5jZUlkIjoiMzZmM2M3ZDktYzg0MS00MmJhLTk0ZTEtMTg3NWVjYmNlMGI2IiwiYXBwRGVmSWQiOiIxMmU1ZDg1NS1lMmI1LTk2MTYtMmUwNS1hMWY0YzFiNzVmZjYiLCJzaWduRGF0ZSI6IjIwMjItMDUtMTdUMTQ6NTk6MTMuNzQ0WiIsInZlbmRvclByb2R1Y3RJZCI6InByZW1pdW0iLCJkZW1vTW9kZSI6ZmFsc2UsImFpZCI6ImZhODQyYmY5LWE1NGEtNGM3YS04NzQ2LTljY2M0NjgzNDhkNiIsInNpdGVPd25lcklkIjoiZTYzYmM0NDQtNDNjZi00MDIwLWE4ZWItOTI1ZjNjZjgzNTZiIn0&amp;currency=EUR&amp;currentCurrency=EUR&amp;commonConfig=%7B%22brand%22%3A%22wix%22%2C%22bsi%22%3A%22a80ed289-6af6-453d-8a73-e55d936b7764%7C1%22%2C%22BSI%22%3A%22a80ed289-6af6-453d-8a73-e55d936b7764%7C1%22%7D&amp;vsi=2a1cd682-48af-4db6-8290-2ecdc92f7b51" allowfullscreen="" allowtransparency="true" allowvr="true" frameborder="0" allow="autoplay;camera;microphone;geolocation;vr"></iframe>
                  </div>
                </div> */}
              </Reveal>
            </div>
          </div>
        </div >
      </section >
    );
  }
}

export default ContactTwo;
