const jhonData = {
  name: "Emmanuel VOISIN",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "MSP-visionary",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/company/hype-monkey/about/",
      className: "social_linkedin",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/hypemonkey.msp/",
      className: "social_instagram",
    },
  ],
  aboutme: "About Me",
  aboutdetails:
    "I am Jhone Deo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever It has survived not only five centuries, but also the leap into electronic but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Z105 - Tan Thinh, Thai Nguyen - Viet Nam",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +1.900.3456.789",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: yourname@somemail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: http://example.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Passion & Veille techno",
      aHead: "La technologie entre dans une nouvelle ère",
      aHeadTwo: "Des solutions innovantes au service de vos projets",
      adiscription:
        "Dans un environnement en constante évolution, projetez-vous dans une vision d’avenir pour rendre votre activité plus agile !",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Matériel Informatique",
      text: "Nous menons également une réflexion approfondie sur la notion du reconditionnement (REFURBISHED) des équipements informatiques pour une informatique plus économe et plus respectueuse de l'environnement.",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "Agégation Internet",
      text: "La technologie d'agrégation internet consiste à réunir, fusionner plusieurs connexions internet (ADSL, SDSL, VDSL,...) de manière à obtenir un seul accès Internet rapide, fiable et à haut débit.",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "Audit & Consulting",
      text: "Notre équipe vous accompagne dans la mise en œuvre, le suivi et l'amélioration de votre infrastructure informatique et systèmes d'informations en apportant des solutions globales et innovantes.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "intel-logo.png",
      ctile: "Prix de l\innovation",
      date: "2016",
      description: "Pour avoir vu avant tout le monde le futur de l\'informatique",
      url: "",
      linktext: "",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonData;
