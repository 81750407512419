import React, { Component } from 'react';
import Clientsliders from 'react-slick';
import Sectiontitle from '../component/Banner/Sectiontitle';

class Clientslogo extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="clients_logo_area" id="partners">
                <div className="container">
                    <Sectiontitle Title="Ils nous font confiance" TitleP="Quelques-un de nos partenaires et clients historiques" />
                    <Clientsliders {...settings} className="clients_slider">
                        <div className="item">
                            <a href=".#!"><img src={require('../image/aramine.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/intel-logo.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/microsoft.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/capsum.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/hnc.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/logofrenchtech.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/capsum.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/hnc.png')} alt="" /></a>
                        </div>
                        <div className="item">
                            <a href=".#!"><img src={require('../image/logofrenchtech.png')} alt="" /></a>
                        </div>
                    </Clientsliders>
                </div>
            </section>
        );
    }

}
export default Clientslogo;