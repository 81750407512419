import React from "react";

export default function Exprience() {
  return (
    <div className="exprience_inner">
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/bemsp.webp")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Remote Monitoring and Mahangement</h4>
          <span>Automatisation et scripts</span>
          <p>
            Déployez les applications favorites et maintenez les applications tierces à jour automatiquement.</p>
          <p>Créez rapidement des scripts puissants pour la surveillance des périphériques à l’aide de votre langage de script préféré et gagnez du temps en téléchargeant des centaines de scripts directement disponibles dans le ComStore.
          </p>
        </div>
      </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/solarwinds.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Protection des postes de travail</h4>
          <span>solarwinds EDR</span>
          <p>
            Les cyberattaques ne cessent de se multiplier et évoluent au quotidien.
            Avec ces menaces, vos clients exigent une sécurité et protection permanente de votre part.
            Alors comment anticiper les menaces et les attaques de ransomwares ?
            Comment récupérer rapidement ses données en cas d’attaques?
          </p>
          <p>
            Avec SolarWinds Endpoint Detection and Response (EDR), empêchez les cyberattaques.
            Prévenez, détectez et corrigez les menaces en constante évolution.
            En cas d’attaque de ransomware, de spywares, de cryptowares,
            ou encore d’attaque de type « zero day », récupérez rapidement l’ensemble de vos données.
          </p>
          <p>
            Afin de minimiser le temps d’arrêt de vos clients,
            SolarWinds EDR propose des outils de correction et de restauration rapide
            afin de neutraliser les effets d’une attaque puis de rétablir
            les points de terminaison à leur dernier état fonctionnel.
          </p>
        </div>
      </div>
      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/blackfog.webp")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Protection et Confidentialité des Données</h4>
          <span>La protection est la meilleur forme de défense</span>
          <p>

            PROTECTION et CONFIDENTIALITÉ DES DONNÉES
            LA PROTECTION EST LA MEILLEURE FORME DE DÉFENSE

            Leader dans le domaine de la confidentialité des données, BlackFog Privacy surveille en permanence le trafic sortant et empêche le flux de données non autorisé de tous vos appareils, garantissant à la fois la confidentialité et la conformité aux réglementations mondiales sur les données.
          </p>
          <p>
            BlackFog se concentre sur la perte des données en temps réel.
            En effet, la solution comporte 12 couches de défense contre les ransomwares,
            les logiciels espions, les logiciels malveillants, phishing, collecte et profilage de données non autorisés.
          </p>
          <p>
            Prenez de l’avance sur les attaques et empêchez les de nuire à vos données.
          </p>
        </div>
      </div>

      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/lioguard.png")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Intégration d'inspecteur de système</h4>
          <span>
            STANDARDISEZ, SÉCURISEZ ET PASSEZ VOS SERVICES MANAGÉS AU NIVEAU SUPÉRIEUR AVEC LIONGARD
          </span>
          <p>
            Offrez à vos équipes un fonctionnement 10 X supérieur avec Liongard, la seule plateforme d’automatisation qui vous offre une visibilité complète sur l’ensemble de votre stack, afin que vous puissiez gérer l’informatique moderne en toute confiance.
          </p>
          <p>
            BlackFog se concentre sur la perte des données en temps réel.
            En effet, la solution comporte 12 couches de défense contre les ransomwares,
            les logiciels espions, les logiciels malveillants, phishing, collecte et profilage de données non autorisés.
          </p>
          <p>
            Prenez de l’avance sur les attaques et empêchez les de nuire à vos données.
          </p>
        </div>
      </div>

      <div className="media exprience_item">
        <div className="media-left">
          <a href=".#">
            <img
              src={require("../../image/developer-img/ninja.webp")}
              alt=""
            />
          </a>
        </div>
        <div className="media-body">
          <h4>Supervision et Bureau à distance</h4>
          <span>Simplifiez-vous le télétravail</span>
          <p>
            Ninja RMM ! incarne la quintessence de la plateforme de RMM.

            Toutes les fonctions dont vous avez besoin sont au premier plan… sans que d’autres options ou réglages viennent vous gêner.
          </p>
        </div>
      </div>
    </div>
  );
}
