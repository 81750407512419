import React, { Component } from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';
class ServiceTwo extends Component {
    render() {
        let { rowFlex } = this.props;
        return (
            <div className="service_area_two bg_color" id="service">
                <div className="container">
                    <div className={`row align-items-center ${rowFlex}`}>
                        <div className="col-lg-5">
                            <div className="service_content">
                                <SectionTitleTwo stitle="Qui suis-je ?" btitle="Emmanuel VOISIN, Fondateur" />
                                <p>
                                    Cela fait plus de 30 ans, que ma passion des nouvelles technologies me pousse perpétuellement à évoluer et à modifier mes visions des systèmes d’informations.
                                </p>
                                <p>
                                    C’est avec une curiosité exacerbée et l’envie de sélectionner les outils les plus innovants, qu’en 2015, je me suis intéressé tout naturellement à la nouvelle approche « MSP (Managed Service Provider) », apportant un suivi proactif dans le management des parcs informatiques (Surveillance réseau 24/7) et sonnant par la même la fin du modèle "BREAK/FIX".
                                </p>
                                <p>
                                    J’ai donc pris la décision d’effectuer depuis plus de 3 ans un travail de qualification en multipliant les tests de validation des outils et des solutions toujours plus nombreuses destinées au MSP.
                                </p>
                                <p>
                                    En renforçant dans un premier temps mes partenariats avec les acteurs « leaders » de cette approche en France comme aux États-Unis « berceau des MSP ». L’objectif premier étant de construire une offre simple basée sur les outils les plus efficaces, agiles et proactifs, dans le respect des obligations légales en vigueur en France (RGPD).


                                </p>
                                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                            Matériel Informarique
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                                            Audit & Consulting
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                                            Agrégation Internet
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="service_img">
                                        <img src={require('../image/service.svg')} alt="" />
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="service_img">
                                        <img src={require('../image/service.svg')} alt="" />
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className="service_img">
                                        <img src={require('../image/service.svg')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceTwo;
