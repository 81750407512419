import React, { Component } from "react";
import Exprience from "./Exprience";

class DeveloperExprience extends Component {
  render() {
    return (
      <section className="exprience_area sec_pad" id="solutions">
        <img
          className="shape one"
          src={require("../../image/img-8.png")}
          alt=""
        />
        <img
          className="shape two"
          src={require("../../image/img-6.png")}
          alt=""
        />
        <img
          className="shape three"
          src={require("../../image/img-4.png")}
          alt=""
        />
        <img
          className="shape four"
          src={require("../../image/developer-img/dot_big.png")}
          alt=""
        />
        <div className="container">
          <div className="dev_tittle mb_70">
            <h2>Nos Solutions</h2>
            <p>Nous utilisions les meilleurs outils du marché pour vous assurer une transformation digitale rapide, simple et sécurisée.</p>
          </div>
          <Exprience />
        </div>
      </section>
    );
  }
}
export default DeveloperExprience;
