import React, { Component } from "react";
import Isotope from "isotope-layout/js/isotope";
import ImagesLoaded from "imagesloaded/imagesloaded";
import YoutubeEmbed from "../YoutubeEmbed";

class DeveloperPortfolio extends Component {
  componentDidMount() {
    var imgLoad = new ImagesLoaded(".grid");

    imgLoad.on("progress", function (instance, image) {
      this.iso = new Isotope(".grid", {
        itemSelector: ".grid-item",
        layoutMode: "masonry",
      });
    });
  }

  render() {
    return (
      <section className="dev_work_area" id="portfolio">
        <div className="container custome_container">
          <div className="row grid">
            <div className="col-lg-6 col-md-6 grid-item">
              <div className="dev_tittle">
                <h2>Des Outils et services sur mesure</h2>
                <p>Dans un environnement en constante évolution, projetez-vous dans une vision d’avenir pour rendre votre activité plus agile !</p>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href=".#">
                  <img
                    src={require("../../image/developer-img/datto.webp")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>Surveillance et gestion</h3>
                  <div className="category">
                    <a href=".#!">RMM</a>
                    <a href=".#!">MSP</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href=".#!">
                  <img
                    src={require("../../image/developer-img/sentinel.webp")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>Protection des postes de travail</h3>
                  <div className="category">
                    <a href=".#!">MDR</a>
                    <a href=".#!">DFIR</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <a href=".#!">
                  <img
                    src={require("../../image/developer-img/sentinel.webp")}
                    alt=""
                  />
                </a>
                <div className="content">
                  <h3>Protection des données</h3>
                  <div className="category">
                    <a href=".#!">RGPD</a>
                    <a href=".#!">RansomWare</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-item">
              <div className="work_item">
                <YoutubeEmbed embedId="VUejBODCBaE" />
                <div className="content">
                  <h3>LionGuard</h3>
                  <div className="category">
                    <a href=".#!">RMM</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 grid-item">
              {/* <a href=".#" className="dev_btn">
                ALL Projects
              </a> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DeveloperPortfolio;
