import React, { Component } from 'react';
import Slider from 'react-slick';

class TestimonialSliderTwo extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1500,
            arrows: false,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div>
                <Slider {...settings} className="testimonial_slider_Two">
                    <div className="item">
                        <p>Tidio has given our clients a quick and easy way to send over thoughts and questions without delay or wait time! No second guessing – just asked and answered quickly in order to make their purchasing decisions easier!</p>
                        <div className="media">
                            <div className="author_img">
                                <img src={require('../../image/sebastienbardon.jpg')} alt="" />
                            </div>
                            <div className="media-body">
                                <h6>Sébastien BARDON</h6>
                                <span>CEO, Capsum</span>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <p>Tidio has given our clients a quick and easy way to send over thoughts and questions without delay or wait time! No second guessing – just asked and answered quickly in order to make their purchasing decisions easier!</p>
                        <div className="media">
                            <div className="author_img">
                                <img src={require('../../image/cmelkonian.jpg')} alt="" />
                            </div>
                            <div className="media-body">
                                <h6>Christophe MELKONIAN</h6>
                                <span>CE0, Aramine</span>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <p>Tidio has given our clients a quick and easy way to send over thoughts and questions without delay or wait time! No second guessing – just asked and answered quickly in order to make their purchasing decisions easier!</p>
                        <div className="media">
                            <div className="author_img">
                                <img src={require('../../image/christianaffre.jpg')} alt="" />
                            </div>
                            <div className="media-body">
                                <h6>Christian AFFRE</h6>
                                <span>CEO, Soluscope</span>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}
export default TestimonialSliderTwo;